import { faMap } from '@abs-warranty/pro-regular-svg-icons/faMap'
import { faFileInvoiceDollar } from '@abs-warranty/pro-regular-svg-icons/faFileInvoiceDollar'

/** @param {import('../lib/sitemap').Sitemap} sitemap */
function mapOnlyPrograms(sitemap) {
  // /map-only
  const mapOnly = sitemap.add({
    href: 'map-only',
    icon: faMap,
    label: 'Map Only Programs',
    fake: true,
    description: 'Maptive replacement - adjudicate claims in Access',
  })

  // /map-only/:slug
  sitemap.add({
    parent: mapOnly,
    icon: faFileInvoiceDollar,
  })
}

export { mapOnlyPrograms }
