import React, { useState } from 'react'
import { Alert, Modal, NavDropdown } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { InputCheckbox } from '@abs-warranty/next-components/form'
import { FontAwesomeIcon } from '@abs-warranty/react-fontawesome'
import { faPersonFromPortal as faPersonFromPortalDuoTone } from '@abs-warranty/pro-solid-svg-icons/faPersonFromPortal'
import { faPersonFromPortal as faPersonFromPortalRegular } from '@abs-warranty/pro-regular-svg-icons/faPersonFromPortal'
import { faCircleInfo } from '@abs-warranty/pro-regular-svg-icons/faCircleInfo'
import { faMicrosoft } from '@abs-warranty/free-brands-svg-icons/faMicrosoft'

/** @returns {JSX.Element} */
function ModalSignOut() {
  const [show, setShow] = useState(false)

  const IDP_CONTROL = 'idp'
  const { register, watch } = useForm({
    defaultValues: { [IDP_CONTROL]: false },
  })
  const idpSignOut = watch(IDP_CONTROL)

  return (
    <>
      <Modal centered show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Sign Out</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="fs-5 mb-2">Are you sure you want to sign out?</p>
          <p className="small">Your session will automatically expire after 8 hours of inactivity.</p>
          <InputCheckbox
            name={IDP_CONTROL}
            // @ts-ignore: type is idp: boolean, not sure why checker is complaining
            register={register}
            label="Completely sign out of my Microsoft Account"
            className="mt-4"
          />
          <Alert variant="info" hidden={!idpSignOut} className="mt-2 mb-0 p-2 small">
            <p className="mb-1">
              <FontAwesomeIcon icon={faCircleInfo} className="me-2 fs-4 float-start pt-1" />
              The next time you sign in, you'll be prompted to enter your username and password followed by multi-factor
              authentication.
            </p>
            <p className="mb-0">
              Unless you're using a shared computer, it's usually not necessary to sign out of your Microsoft Account.
            </p>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <a className="btn btn-primary shadow-sm" href={idpSignOut ? '/api/v3/auth/signout?idp=true' : '/signout'}>
            Sign Out
            <FontAwesomeIcon icon={idpSignOut ? faMicrosoft : faPersonFromPortalRegular} className="ms-2" />
          </a>
        </Modal.Footer>
      </Modal>
      <NavDropdown.Item onClick={() => setShow(!show)}>
        <FontAwesomeIcon icon={faPersonFromPortalDuoTone} fixedWidth className="me-2 text-cold-steel" size="lg" />
        Sign out
      </NavDropdown.Item>
    </>
  )
}

export default ModalSignOut
