import { faPersonToPortal } from '@abs-warranty/pro-regular-svg-icons/faPersonToPortal'
import { Sitemap } from './lib/sitemap'
import { snakeToCamel } from './lib/snake-to-camel'
import { admin } from './map/admin'
import { dataIngestion } from './map/data-ingestion'
import { tireProtectionPrograms } from './map/tire-protection-programs'
import { localLaborPrograms } from './map/local-labor-programs'
import { reports } from './map/reports'
import { shops } from './map/shops'
import { tools } from './map/tools'
import { motorClubPrograms } from './map/motor-club-programs'
import { mapOnlyPrograms } from './map/map-only-programs'

const sitemap = new Sitemap({ label: 'Portal', icon: faPersonToPortal })
admin(sitemap)
dataIngestion(sitemap)
localLaborPrograms(sitemap)
reports(sitemap)
shops(sitemap)
tireProtectionPrograms(sitemap)
motorClubPrograms(sitemap)
mapOnlyPrograms(sitemap)
tools(sitemap)

export { sitemap, snakeToCamel }

/**
 * @callback pageFn
 * @param {string} href
 * @param {string} label
 * @returns {import('./lib/page').Page}
 */
